<template>
    <v-container>
        <AConfirmation :isShow="isPending" @cancel="cancelSubmit" @confirm="submit"/>
        <v-skeleton-loader v-if="api.isLoading" type="table" ref="skeleton">
        </v-skeleton-loader>
        <ASuccessWithoutReload  @close="closeSuccessModal" :api="this.api"/>
        <v-dialog
            v-model="isUpdating"
            height="700"
            width="700">
            <ComponentUpdateDropdown
            :id="this.isUpdatingId"
            @finish="fetch()"
            v-if="this.isUpdating"
            @close="()=> {
                this.isUpdating = false;
                this.isUpdatingId = null;
            }"/>
        </v-dialog>
        <v-dialog
            v-model="isAdding"
            height="700"
            width="700">
            <ComponentAddDropDown
            @finish="fetch()"
            v-if="this.isAdding"
            @close="()=> {
                this.isAdding = false;
            }"/>

        </v-dialog>
        <v-data-table
            v-if="!api.isLoading"
            :items="data"
            :search="search"
            :headers="dataHeader">
            <template
                v-slot:top>
                <v-toolbar
                    flat
                    class="text-h4 pt-4"
                    height="auto">
                    <v-row
                        no-gutters>
                        <v-col
                            class="mt-3 mx-3">
                            <v-row
                                class="mb-3">
                                <v-toolbar-title
                                    class="font-weight-bold text-h4 my-3">
                                    List of Drop down options
                                </v-toolbar-title>
                                <v-spacer></v-spacer>
                              
                                <v-btn
                                    color="primary"
                                    @click="openAddModal()">
                                    New
                                </v-btn>
                            </v-row>
                            <v-row>
                                <v-autocomplete
                                :items="dropDownOptions"
                                v-model="type"
                                label="Category"
                                dense 
                                @change="fetch"
                                outlined>

                                </v-autocomplete>
                            </v-row>
                            <v-row>
                                <v-text-field
                                    v-model="search"
                                    dense
                                    outlined 
                                    label="Search">

                                </v-text-field>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-toolbar>

            </template>
            <template
                v-slot:item.index="{item}">
                {{ data.indexOf(item) + 1 }}
            </template>
            <template
                v-slot:item.description="{item}">
                <span
                    v-if="item.description!=null">
                    {{ item.description }}
                </span>
                <span
                    v-else>
                    -
                </span>
            </template>
            <template
                v-slot:item.created_at="{item}">
                <span
                    v-if="item.created_at!=null">
                    {{ convertTimeZone(item.created_at) }}

                </span>
            </template>
            <template
                v-slot:item.action="{item}">
                <v-col>
                    <v-row>
                        <v-btn
                            color="primary"
                            plain
                            @click="openUpdateModal(item.dropdown_id)">
                            Edit
                        </v-btn>
                        <v-btn
                            color="error"
                            plain
                            @click="deleteDropdown(item.dropdown_id)">
                            Delete
                        </v-btn>
                    </v-row>
                </v-col>
            </template>
        </v-data-table>
    </v-container>
</template>
  
<script>
import { mapState } from 'vuex'
import ASuccessWithoutReload from '../../components/common/ASuccessWithoutReload.vue';
import ComponentUpdateDropdown from '../../components/form/ComponentUpdateDropDown.vue';
import ComponentAddDropDown from '../form/ComponentAddDropDown.vue';
export default {
    components:{
    ASuccessWithoutReload,
    ComponentUpdateDropdown,
    ComponentAddDropDown
},

    computed: mapState({
    //
}),
    props:[
    //
    ],
    data: () => ({
        search:"",
        data:null,
        dataHeader:[
            {
                text:'#',
                value:'index',
            },
            {
                text:'Categories',
                value:'key'
            },
            {
                text:'Options',
                value:'value',
            },
            {
                text:'Description',
                value:'description'
            },
            {
                text:'Created by',
                value:'email'
            },
            {
                text:'Created date',
                value:'created_at'
            },
            {
                text:'action',
                value:'action'
            }
        ],
        type:'all',
        isUpdating:false,
        isUpdatingId:null,
        isPending:false,
        isAdding:false,
        dropDownOptions:[
        "all",
        "payment_channel",
        "account_type",
        "pricing",
        "webinar",
        "refund",
        "location",
        "yyc_user_position",
        "renewal_status",
        "cancellation_reason",
        "aae_price",
        "position",
        "bonus_offer",
        ],
        api:
            {
                isLoading : false,
                isError:false,
                error:null,
                url:null,
                isSuccesful:false,
                success:null,
            }
    }),
    created() {
        this.api.callbackReset = () => {
            this.api.isLoading = true;
            this.api.isError = false;
        };
        this.api.callbackError = (e) => {
            this.api.isLoading = false;
            this.api.isError = true;
            this.api.error = e;
        };
        this.api.callbackSuccess = (resp) => {
            if(resp.class==='getListOfDropdown') {
                this.data = resp.data;
            }
            if(resp.class==='removeDropDown') {
                this.api.isSuccesful = true;
                this.api.success = "Succesfully remove the dropdown options";
            }
            this.api.isLoading = false;
            this.api.isError = false;
        }

    },
    mounted() {
        this.fetch();
    },
    methods: {
        fetch(){
            this.isUpdating = false;
            this.isUpdatingId = null;
            this.$api.fetch(this.fetchDropdownOptions());
        },
        fetchDropdownOptions() {
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/developer/excel/dropdown?type="+this.type;
            return tempApi;
        },
       
        // BOC : Create a new function to build the api and return it to the fetch function

        // EOC
        validateInput(){
            this.isPending=true;
        },
        cancelSubmit(){
            this.isPending = false;
        },
        submit(){
            this.isPending = false;
            let reApplyCouponApi = this.reApplyCoupon();
            this.$api.fetch(reApplyCouponApi);
        },
        closeSuccessModal() {
            this.api.isSuccesful = false;
            this.fetch();
        },
        openUpdateModal(id) {
            this.isUpdatingId = id;
            this.isUpdating = true;
        },
        openAddModal() {
            this.isAdding = true;
        },
        deleteDropdown(id) 
        {
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "DELETE";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/developer/excel/dropdown/options/"+id;
            tempApi.params = {
                'type':'salesperson'
            }
            this.$api.fetch(tempApi);
        },
        convertTimeZone(time){
            return this.$moment(time).format('LLL')

        }
    }
}
</script>