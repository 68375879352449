<template>
    <v-container
        style="background-color:white">
        <ASuccessWithoutReload
            :api="this.api"
            @close="$emit('finish')"
        />
        <AConfirmation :isShow="isPending" @cancel="cancelSubmit" @confirm="submit" />
        <v-skeleton-loader
            v-if="api.isLoading || form==null || keysOptions==null" ref="skeleton" type="table">
        </v-skeleton-loader>
        <v-row
           v-if="!api.isLoading && form!=null && keysOptions != null">
            <v-col
                class="pa-0"
                cols="12"
                sm="12">
                <v-card
                    class="py-4">
                    <v-toolbar
                        class="secondary ma-4">
                        <v-toolbar-title
                            class="white--text text-h4">
                            Edit Dropdown options
                        </v-toolbar-title>
                    </v-toolbar>
                    <v-container>
                    <v-row
                        class="mx-2">
                        <v-col
                        cols="12"
                        sm="12">
                            <!-- <div
                                class="d-flex justify-start">
                                ** Put the form field here
                            </div> -->
                            <div
                                class="d-flex justify-start">
                                <v-autocomplete
                                    :items="keysOptions"
                                    v-model="form.key"
                                    dense 
                                    outlined
                                    label="Category">
                                </v-autocomplete>
                            </div>
                            <div
                                class="d-flex justify-start">
                                <v-text-field
                                    v-model="form.value"
                                    dense
                                    outlined
                                    label="Value">

                                </v-text-field>
                            </div>
                            <div
                                class="d-flex justify-start">
                                <v-text-field
                                    v-model="form.description"
                                    dense 
                                    outlined 
                                    label="Description">
                                </v-text-field>

                            </div>
                            <div
                                class="d-flex justify-end">
                                <v-btn
                                    @click="closeModal"
                                    color="primary"
                                    plain>
                                    Cancel
                                </v-btn>
                                <v-btn
                                    @click="validateInput"
                                    color="primary">
                                    Submit
                                </v-btn>
                            </div>
                        </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
  
<script>
import { mapState } from 'vuex'
import ASuccessWithoutReload from '../common/ASuccessWithoutReload.vue';
import AConfirmation from '../common/AConfirmation.vue';
export default {
    components:{
    AConfirmation,
    ASuccessWithoutReload,
},
    computed: mapState({
    //
    }),
    props:[
        'id'
    ],
    data: () => ({
        isPending:false,
        form:{
            keys:null,
            value:null,
            description:null,
        },
        keysOptions:null,
        data:null,

        api:
            {
                isLoading : false,
                isError:false,
                error:null,
                url:null,
                isSuccesful:false,
                success:null,
            },
       
    }),
    created() {
        this.api.callbackReset = () => {
            this.api.isLoading = true;
            this.api.isError = false;
        };
        this.api.callbackError = (e) => {
            this.api.isLoading = false;
            this.api.isError = true;
            this.api.error = e;
        };
        this.api.callbackSuccess = (resp) => {
           
            if(resp.class==="getDropdownById") {
                this.form = resp.data;
            }
            if(resp.class==='getListOfKeys') {
                this.keysOptions = resp.data;
            }
            if(resp.class==="updateDropdownKeys") {
                this.api.isSuccesful = true;
                this.api.success = "Succesfully update the detail";
            }
            this.api.isLoading = false;
            this.api.isError = false;
            this.isSucessful = true;
        }

    },
    mounted() {
        this.fetch();
    },
    methods: {
        fetch(){
            this.$axios.all([
                this.$api.fetch(this.fetchDropDownOptions()),
                this.$api.fetch(this.fetchDropDownKeys()),

            ]);
        },
        // BOC : Create a new function that return the api instance to the fetch function to retrieve the current value want to updated
        fetchDropDownOptions() {
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = process.env.VUE_APP_SERVER_API+'/developer/excel/dropdown/'+this.id;
            return tempApi;
        },
        fetchDropDownKeys() {
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/developer/excel/dropdown/options/keys";
            return tempApi;
        },
        // EOC
        validateInput(){
            this.isPending = true;
        },
        submit()
        {
            this.isPending = false;
            this.$api.fetch(this.updateDropDownOptions());
        },
        cancelSubmit(){
            this.isPending = false;
        },
        create(){
          
        },
        updateDropDownOptions() {
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "PUT";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/developer/excel/dropdown/options/"+this.id;
            tempApi.params = {
                'key':this.form.key,
                'value':this.form.value,
                'description':this.form.description,
                'user_id':this.$store.getters.getUserId,
            };
            return tempApi;

        },
        updateDataPaymentDate(data) {
            this.form.subscriptionPaymentDate = data;
        },
        convertTimeToDay(time) {
            return time.split(" ")[0];
        },
        closeModal() {
            this.$emit("close")
        }
    }
}
</script>